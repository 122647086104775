// Conveniently import this file anywhere to use db

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAMoZVKl42BOGEupQzFbU5xqBjsXpwj5yY",
  authDomain: "dnd-visual-bestiary.firebaseapp.com",
  projectId: "dnd-visual-bestiary",
  storageBucket: "dnd-visual-bestiary.appspot.com",
  messagingSenderId: "144648916672",
  appId: "1:144648916672:web:35c8b18eaf38405bafe725",
  measurementId: "G-9GZP0RVDEY",
};

export const db = firebase.initializeApp(firebaseConfig).firestore();
export const monsterTagsCollection = db.collection("monster_tags");

firebase.analytics();

// Export types that exists in Firestore - Uncomment if you need them in your app
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }
