<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-title><div>D&amp;D Visual Bestiary</div></v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="!isAuthenticated" @click="signInWithGoogle()">
        <span class="mr-2">Sign in to edit...</span>
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-2">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({}),

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  methods: {
    ...mapActions("auth", ["signInWithGoogle"]),
  },
};
</script>
