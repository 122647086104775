import sourceIndex from "@/data/bestiary/fluff-index.json";
import { monsterTagsCollection } from "@/db";

export class MonsterFluff {
  source = null;
  name = null;
  image = null;
  tagsDocId = null;
  tags = [];

  constructor(source, name, image, tagsDocId, tags) {
    this.source = source;
    this.name = name;
    this.image = image;
    this.tagsDocId = tagsDocId;
    this.tags = tags;
  }
}

async function getMonsterFluff() {
  let fluffDataPromises = [];
  for (let key in sourceIndex) {
    let filename = sourceIndex[key];
    fluffDataPromises.push(import(`@/data/bestiary/${filename}`));
  }
  const fluffData = await Promise.all(fluffDataPromises);
  const monsterTags = await getMonsterTags();
  let monsterFluffs = [];
  for (const sourceFluffs of fluffData) {
    for (const fluff of sourceFluffs["monsterFluff"]) {
      const fluffImage = getFluffImage(fluff);
      if (fluffImage != null) {
        let tagsDocId = null;
        let tags = null;
        const tagsEntry = monsterTags[[fluff.source, fluff.name]];
        if (tagsEntry != null) {
          tagsDocId = tagsEntry.id;
          tags = tagsEntry.data().tags;
        }
        monsterFluffs.push(
          new MonsterFluff(
            fluff.source,
            fluff.name,
            fluffImage,
            tagsDocId,
            tags
          )
        );
      }
    }
  }
  return monsterFluffs;
}

async function getMonsterTags() {
  let monsterTags = {};
  const tagEntry = await monsterTagsCollection.get();
  tagEntry.forEach((entry) => {
    const entryData = entry.data();
    monsterTags[[entryData.source, entryData.name]] = entry;
  });
  return monsterTags;
}

function getFluffImage(fluff) {
  if (
    Array.isArray(fluff["images"]) &&
    fluff["images"].length > 0 &&
    fluff["images"][0]["type"] === "image" &&
    fluff["images"][0]["href"]["type"] === "internal"
  ) {
    return fluff.images[0].href.path;
  }
  return null;
}

export { getMonsterFluff };

function removeMonsterTag(fluff, tagToRemove) {
  fluff.tags = fluff.tags.filter((tag) => {
    return tag !== tagToRemove;
  });
  monsterTagsCollection.doc(fluff.tagsDocId).update({ tags: fluff.tags });
}

export { removeMonsterTag };

async function addMonsterTag(fluff, tagToAdd) {
  if (fluff.tagsDocId == null) {
    fluff.tags = [tagToAdd];
    const ref = await monsterTagsCollection.add({
      source: fluff.source,
      name: fluff.name,
      tags: fluff.tags,
    });
    fluff.tagsDocId = ref.id;
  } else {
    fluff.tags.push(tagToAdd);
    monsterTagsCollection.doc(fluff.tagsDocId).update({ tags: fluff.tags });
  }
}

export { addMonsterTag };
