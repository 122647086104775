<template>
  <v-container>
    <v-row>
      <v-col cols="5">
        <v-autocomplete
          v-model="includeTags"
          outlined
          solo
          dense
          chips
          clearable
          deletable-chips
          small-chips
          label="Include Filter"
          :items="allTags"
          multiple
        ></v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="excludeTags"
          outlined
          filled
          solo-inverted
          dense
          chips
          clearable
          deletable-chips
          small-chips
          label="Exclude Filter"
          :items="allTags"
          color="red"
          multiple
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="filterSources"
          outlined
          solo
          dense
          clearable
          label="Sources"
          multiple
          :items="allSources"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="grey--text text-caption">
              {{ filterSources.length }} of {{ allSources.length }}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="fluff in fluffs">
        <v-col :key="`${fluff.source}-${fluff.name}`" cols="6" md="2">
          <monster-card :fluff="fluff"></monster-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { getMonsterFluff } from "@/bestiary";
import MonsterCard from "@/components/MonsterCard.vue";

export default {
  name: "BestiaryList",

  components: {
    "monster-card": MonsterCard,
  },

  data: () => ({
    allFluffs: [],
    allTags: [],
    includeTags: [],
    excludeTags: [],
    allSources: [],
    filterSources: [],
  }),

  computed: {
    fluffs() {
      if (this.includeTags.length == 0 && this.excludeTags.length == 0) {
        return this.allFluffs.filter((fluff) => {
          return this.filterSources.includes(fluff.source);
        });
      }
      return this.allFluffs.filter((fluff) => {
        if (!this.filterSources.includes(fluff.source)) {
          return false;
        }
        for (const tag of this.excludeTags) {
          if (fluff.tags != null && fluff.tags.includes(tag)) {
            return false;
          }
        }
        for (const tag of this.includeTags) {
          if (fluff.tags == null || !fluff.tags.includes(tag)) {
            return false;
          }
        }
        return true;
      });
    },
  },

  async created() {
    this.allFluffs = await getMonsterFluff();
    let allTags = new Set();
    let allSources = new Set();
    for (const fluff of this.allFluffs) {
      if (fluff.tags != null) {
        for (const tag of fluff.tags) {
          allTags.add(tag);
        }
      }
      allSources.add(fluff.source);
    }
    this.allTags = Array.from(allTags);
    this.allTags.sort();
    this.allSources = Array.from(allSources);
    this.allSources.sort();
    this.filterSources = this.allSources;
  },
};
</script>
