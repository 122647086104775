<template>
  <bestiary-list></bestiary-list>
</template>

<script>
import BestiaryList from "../components/BestiaryList";

export default {
  name: "Home",

  components: {
    BestiaryList,
  },
};
</script>
