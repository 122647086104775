<template>
  <v-card>
    <v-card-title>{{ fluff.name }}</v-card-title>
    <v-card-subtitle>{{ fluff.source }}</v-card-subtitle>
    <v-card-text>
      <v-img :src="`img/${fluff.image}`"></v-img>
      <v-text-field
        v-if="isAuthenticated"
        ref="tagInput"
        v-model.lazy.trim="curTagText"
        label="add tag"
        :rules="[rules.validTag]"
        @keydown.enter="addTag()"
      ></v-text-field>
      <div>
        <v-chip
          v-for="tag in fluff.tags"
          :key="tag"
          :close="isAuthenticated"
          @click:close="removeTag(tag)"
          >{{ tag }}</v-chip
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { MonsterFluff, removeMonsterTag, addMonsterTag } from "@/bestiary";

export default {
  name: "MonsterCard",
  props: {
    fluff: {
      type: MonsterFluff,
      default: () => {
        return null;
      },
    },
  },

  data: () => ({
    curTagText: "",
    rules: {
      validTag: (value) => {
        const pattern = /^[a-z0-9_-]*$/;
        return pattern.test(value) || "Invalid tag.";
      },
    },
  }),

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  methods: {
    removeTag(tagToRemove) {
      removeMonsterTag(this.fluff, tagToRemove);
    },
    async addTag() {
      const tagToAdd = this.curTagText;
      this.curTagText = "";
      if (tagToAdd === "") {
        return; // ignore empty tags so we don't have to invalidate
      }
      if (!this.$refs.tagInput.validate()) {
        return;
      }
      if (this.fluff.tags != null && this.fluff.tags.includes(tagToAdd)) {
        return; // it's a dupe, just ignore it
      }
      await addMonsterTag(this.fluff, tagToAdd);
    },
  },
};
</script>
