import firebase from "firebase/app";
import "firebase/auth";

export default {
  signInWithGoogle({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        commit("SET_CURRENT_USER", { ...result.user })
      })
      .catch((err) => {
        console.log(err);
      });
  },
}
